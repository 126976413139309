import { Controller } from "@hotwired/stimulus"

import $ from 'jquery';
import 'jqueryui';
import 'jquery-ui-rotatable';

export default class extends Controller {
  connect() {
    const event = new Event("draggables-connected", { detail: "initial"});
    document.dispatchEvent(event);

    document.addEventListener("editing-on", (e) => {
      this.setupDraggable(e.detail);
      const event = new Event("draggables-connected", { detail: "edited"});
      document.dispatchEvent(event);
    })

    document.addEventListener("editing-off", () => {
      this.stopDraggable();
    })
  }

  setupDraggable(zoom=1) {
    let id = $(this.element).attr('id');
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const position = { x: 0, y: 0 }

    let dx;
    let dy;
    var recoupLeft, recoupTop;

    $(this.element).draggable()
    $(this.element).draggable("destroy")
    $(this.element).draggable({
      drag: function (event, ui) {
        // Fixes dragging with css scale set
        dx = ui.position.left - ui.originalPosition.left;
        dy = ui.position.top - ui.originalPosition.top;
        ui.position.left = ui.originalPosition.left + ( dx/zoom);
        ui.position.top = ui.originalPosition.top + ( dy/zoom );
        ui.position.left += recoupLeft;
        ui.position.top += recoupTop;
      },
      start: function (event, ui) {
        var left = parseInt($(this).css('left'), 10);
        left = isNaN(left) ? 0 : left;
        var top = parseInt($(this).css('top'), 10);
        top = isNaN(top) ? 0 : top;
        recoupLeft = left - ui.position.left;
        recoupTop = top - ui.position.top;
      },
      stop: function(event, ui) {
        let positions = JSON.parse(localStorage.positions || "{}");
        positions[id] = {...positions[id], ...ui.position}
        localStorage.positions = JSON.stringify(positions)

        $.ajax({
          url: '/yard_areas/top_view',
          method: 'POST',
          dataType: 'json',
          data: { yard_areas_top_view: { configuration: positions }},
          beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);
          }
        }).done(response => {
          // Notifications that config has been saved
        })
      }
    });

    $(this.element).rotatable({})
    $(this.element).rotatable("destroy")
    $(this.element).rotatable({
      wheelRotate: false,
      stop: function(event, ui) {
        let positions = JSON.parse(localStorage.positions || "{}");
        let radians = ui.angle.current;
        positions[id]["transform"] = `rotate(${radians}rad)`
        console.log(positions);

        localStorage.positions = JSON.stringify(positions)

        $.ajax({
          url: '/yard_areas/top_view',
          method: 'POST',
          dataType: 'json',
          data: { yard_areas_top_view: { configuration: positions }},
          beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', csrfToken);
          }
        }).done(response => {
          // Notifications that config has been saved
        })

      }
    })
  }

  stopDraggable(){
    $(this.element).draggable("destroy")
    $(this.element).rotatable("destroy")
  }
}
