import { Controller } from "@hotwired/stimulus"

/* ===== EXAMPLE =====
  <div>
    <input
      name="cargo_type"
      data-controller="show-if"
      data-action="show-if#showItems"
    >
    </input>

    <!-- Container Section -->
    <div
      class="field"
      data-show-if-name="cargo_type"
      data-show-if-equal-to="container"
    >
      <label for="container_id">Container</label>
      <select name="container_id">
        ...
      </select>
    </div>

    <!-- Breakbulk Section -->
    <div
      class="field"
      data-show-if-name="cargo_type"
      data-show-if-equal-to="breakbulk"
    >
      <label for="breakbulk_id">General Cargo</label>
      <select name="breakbulk_id">
        ...
      </select>
    </div>
  </div>
*/
export default class extends Controller {
  //////////////////
  // Initializers //
  //////////////////

  connect() {
    const name   = this.element.name;
    const value  = this.element.value;
    this.$targetElements = $(`[data-show-if-name='${name}']`);

    if(value) this.showItems();
  }

  /////////////////
  //// Actions ////
  /////////////////

  showItems() {
    const actualValue = this.fetchValue(this.element);
    if (actualValue === undefined) return;

    // Hide all target elements by default
    this.$targetElements.addClass('hidden');

    this.$targetElements.each(function() {
      const $target = $(this);
      const showIfEqualTo = $target.data('show-if-equal-to');
      const showIfNotEqualTo = $target.data('show-if-not-equal-to');
      const showIfPresent = $target.data('show-if-present');
      const showIfBlank = $target.data('show-if-blank');

      if (
        (showIfEqualTo !== undefined && actualValue === showIfEqualTo) ||
        (showIfNotEqualTo !== undefined && actualValue !== showIfNotEqualTo) ||
        (showIfPresent !== undefined && actualValue) ||
        (showIfBlank !== undefined && !actualValue)
      ) {
        $target.removeClass('hidden');
      }
    });
  }

  /////////////////
  //// Helpers ////
  /////////////////
  fetchValue(element) {
    let actualValue;

    // Modified to support checkbox & radio
    switch (element.type) {
      case "checkbox":
        actualValue = element.checked ? "checked" : "unchecked";
        break;
      case "radio":
        if (!element.checked) return undefined;
        actualValue = element.value;
        break;
      default:
        actualValue = element.value;
    }

    return actualValue;
  }
}
