import { Controller } from "@hotwired/stimulus"
import tinycolor from 'tinycolor2';

// TODO needs to happen after draggable is added to each yard area
export default class extends Controller {
  static values = {
    scale: { type: Number, default: 1 },
    editing: { type: Boolean, default: false },
    configuration: { type: Object, default: {} },
    sizeFilter: { type: Array, default: [] },
    typeFilter: { type: Array, default: [] },
    statusFilter: { type: Array, default: [] },
    usageFilter: { type: Array, default: [] },
    colorBy: { type: String, default: 'size' }
  }

  static targets = [
    "editButton",
    "colorByFilter",
    "yardSlot",
    "yardArea",
    "content",
    "filterLegend"
  ]

  connect() {
    this.scaleValue = JSON.parse(localStorage.scale || this.scaleValue);
    const zoom = new CustomEvent("zoom", { detail: this.scaleValue});
    $(this.contentTarget).css({"transform-origin": "left top", "transform": `scale(${this.scaleValue})`})

    document.addEventListener("draggables-connected", (e) => {
      if (e.detail === "initial") {
        let config = this.configurationValue
        let positions = this.convertInt(config)
        // Set localstorage for individual yard areas to read/write
        localStorage.positions = JSON.stringify(positions)

        $.each(positions, function(id, pos) {
          let css = {...pos, "position": "relative"}
          $("#" + id).css(css)
        })
      } else {
        let positions = JSON.parse(localStorage.positions || "{}");

        $.each(positions, function(id, pos) {
          let css = {...pos, "position": "relative"}
          $("#" + id).css(css)
        })
      }
    })

    this.clearSlotColors();
    this.refresh();
    this.contentTarget.classList.remove("hidden");
  }

  changeColorBy(e) {
    localStorage.colors = JSON.stringify({});
    localStorage.filter = e.target.value;
    this.colorBy(e.target.value);
  }

  clearSlotColors() {
    this.yardSlotTargets.forEach((slot) => {
      $(slot).css("fill", "white")
      $(slot).siblings().css("fill", "black");
    })
  }

  colorBy(value) {
    let statusColors = [
      '#FF5050', // empty
      '#5DFA5C', // full
    ]
    let colors = [
      "#00CF1C",
      "#BD5B00",
      "#BFF5FF",
      "#B300FF",
      "#A10086",
      "#3059BA",
      "#D1A700",
      "#FFF200",
      "#704700",
      "#FF00D4",
      "#A885FF",
      "#525252",
      "#00A5C2",
      "#AB0000",
      "#2FFF27",
      "#FF78A1",
      "#1A0845",
      "#3BDEFF",
      "#ADADAD",
      "#FF0023",
      "#1D9E18",
      "#00FFAA",
      "#00A6FF",
      "#5B1BF3",
      "#C5FF8A",
      "#FFBA00",
      "#FF7100"
    ]

    const filters = {
      size: {filterValue: 'sizeFilterValue', dataValue: 'occupiedSize'},
      type: {filterValue: 'typeFilterValue', dataValue: 'containerType'},
      status: {filterValue: 'statusFilterValue', dataValue: 'containerStatus'},
      usage: {filterValue: 'usageFilterValue', dataValue: 'containerUsage'},
    }
    // Get colors if they have been stored
    let storedColors = JSON.parse(localStorage.colors || "{}");
    this.clearSlotColors()

    let filterValue = filters[value]['filterValue']
    let dataValue = filters[value]['dataValue']
    let filterColors;

    if (Object.keys(storedColors).length === 0) {
      let colorArray = this[filterValue].map((filter) => {
        if (filterValue == 'statusFilterValue') {
          return [filter,  statusColors.shift()]
        } else {
          return [filter,  colors.shift()]
        }
      })
      filterColors = Object.fromEntries(colorArray);
    } else {
      filterColors = storedColors;
    }


    this.yardSlotTargets.forEach((slot) => {
      let filter = slot.dataset[dataValue]
      if (filter) {
        let filterColor = filterColors[filter];
        $(slot).css("fill", filterColor)
        let textColor = tinycolor.mostReadable(filterColor, ["black", "white"])?.toHexString();
        $(slot).siblings().css("fill", textColor);
      }
    })

    this.updateLegend(filterColors);

    // Store color
    localStorage.colors = JSON.stringify(filterColors);
  }

  refresh() {
    localStorage.colors = JSON.stringify({});
    this.colorBy(localStorage.filter || 'size');
  }

  moveAll() {
    let confirmed = confirm("Move yard areas to original positions?")

    if (confirmed) {
      $(this.yardAreaTargets).css("top", 0)
      $(this.yardAreaTargets).css("left", 0)
      $(this.yardAreaTargets).css("transform", "rotate(0)")

      localStorage.positions = JSON.stringify({})

      $.ajax({
        url: '/yard_areas/top_view',
        method: 'POST',
        dataType: 'json',
        data: { yard_areas_top_view: { configuration: {} }},
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', csrfToken);
        }
      }).done(response => {
        // Notifications that config has been saved
      })
    }
  }

  updateLegend(colors) {
    $(this.filterLegendTarget).empty();
    for (let [filterLabel, color] of Object.entries(colors)) {
      filterLabel = filterLabel === "0" ? "Unknown" : filterLabel
      $(this.filterLegendTarget).append(`<li><span style="background-color: ${color};"></span>${filterLabel}</li>`)
    }
  }

  toggleEdit() {
    this.editingValue = !this.editingValue
    if (this.editingValue) {
      this.editButtonTarget.classList.add("active");
      document.dispatchEvent(new CustomEvent("editing-on", { detail: this.scaleValue}));
    } else {
      this.editButtonTarget.classList.remove("active");
      document.dispatchEvent(new CustomEvent("editing-off"));
    }
  }

  zoomIn() {
    this.scaleValue += 0.1
    localStorage.scale = this.scaleValue
    const zoom = new CustomEvent("zoom", { detail: this.scaleValue});
    $(this.contentTarget).css({"transform-origin": "left top", "transform": `scale(${this.scaleValue})`})

    document.dispatchEvent(zoom);
  }

  zoomOut() {
    this.scaleValue -= 0.1
    localStorage.scale = this.scaleValue
    const zoom = new CustomEvent("zoom", { detail: this.scaleValue});
    $(this.contentTarget).css({"transform-origin": "left top", "transform": `scale(${this.scaleValue})`})

    document.dispatchEvent(zoom);
  }

  convertInt(obj) {
    for (var key in obj) {
      for (var prop in obj[key]) {
        if (prop === "top" || prop === "left") {
          obj[key][prop] = parseInt(obj[key][prop], 10);
        }
      }
    }
    return obj;
  }
}
