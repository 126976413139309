import { Controller } from "@hotwired/stimulus"

/*
USAGE:

<div data-controller="modal">
  <!-- Action: Button that triggers the modal>
  <button data-action="modal#showDialog">Popup!</button>
  (OR)
  <span data-action="click->modal#showDialog">Popup!</span>

  <!-- Target: Dialog Box>
  <div class="modal fade" data-modal-target="dialog">
    <div class="dialog">
      <div class="content">
        .. <content goes here>
      </div>
    </div>
  </div>
</div>
*/
export default class extends Controller {
  static targets = [ "dialog" ];

  showDialog(event) {
    event.preventDefault();
    $(this.dialogTarget).modal("show");
  }
}
